<template>
    <div>
        <b-overlay :show="show" spinner-variant="primary" spinner-type="grow" spinner-small></b-overlay>
        <b-card>
            <b-row>
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="4" class="my-1">

                </b-col>
                <!-- <b-col md="3" sm="8" class="my-1">
                  
                </b-col> -->
                <b-col md="4" class="my-1">
                    <b-form-group label="" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search"
                                placeholder="Type to Search" />
                        </b-input-group>
                    </b-form-group>

                </b-col>
                <b-col md="2" class="my-1">

                    <b-button block size="sm" v-b-modal.modal-1 variant="primary">
                        <feather-icon icon="PlusIcon" size="15" /> add
                    </b-button>

                </b-col>

                <b-col cols="12">
                    <b-table striped  hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                        @filtered="onFiltered">

                        <template #cell(deskripsi)="data">
                            <b-row>
                                <b-col md="4">

                                    <small>Jenis Pembayaran : </small>
                                    <h4 class="text-primary">{{ data.item.nm_jenis_pembayaran}}</h4>


                                </b-col>
                                <b-col md="6">
                                    <small>Keterangan : </small>
                                    <b-alert show variant="primary">
                                        <div class="alert-body">
                                        <p style="text-align: justify;" v-html="data.item.keterangan"></p> 
                                        </div> 
                                    </b-alert>

                                </b-col>
                                <b-col md="2">
                                    <b-row>
                                        <b-col md="12">
                                            <b-button block size="sm" variant="primary" v-b-modal.modal-2 @click="detailData(data.item)">
                                                <feather-icon icon="AlignJustifyIcon" size="15" /> Detail
                                            </b-button>
                                        </b-col>
                                        <b-col md="12" v-if="data.item.st !='1'">
                                            <b-button block size="sm" variant="warning" class="mt-1"
                                                @click=hapusData(data.item.id)>
                                                <feather-icon icon="TrashIcon" size="15" /> Hapus
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="data.item.st =='1'" class="mt-1">
                                        <b-col md="12">
                                            <b-button block size="sm" variant="outline-primary"
                                                v-if="data.item.status_pembayaran =='Aktif'"
                                                @click="updateStatus(data.item.id)">
                                                <feather-icon icon="CheckCircleIcon" size="15" /> Status Aktif
                                            </b-button>
                                            <b-button block size="sm" variant="outline-warning"
                                                v-if="data.item.status_pembayaran !='Aktif'"
                                                @click="updateStatus(data.item.id)">
                                                <feather-icon icon="AlertCircleIcon" size="15" /> Non Aktif
                                            </b-button>
                                        </b-col>

                                    </b-row>
                                </b-col>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="6">
                    Jumlah Data <strong> {{ totalRows }} </strong> row
                </b-col>

                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
                        size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-card>
        <add @simpan='simpan' :input =  input></add>
        <detail :input = input @update='update'></detail>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base'
    import axios from '@/config/Axios'
    import add from './component/add.vue'
    import detail from './component/detail.vue'
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BAlert,
        BOverlay
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BOverlay,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCard,
            add,
            detail,
            BAlert
        },
        mixins: [Base],
        data() {
            return {
                show: true,
                input : {},
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'deskripsi',
                    label: '',
                }],
                items: [],
                status: [],
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() { 
            this.loadData();
        },
        methods: {
            detailData(rs){
                this.input = rs
            },
            async loadData() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/setup/jenis_pembayaran/load',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;
                        self.show = false;
                        self.totalRows = self.items.length;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async updateStatus(id) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/setup/jenis_pembayaran/status',
                        data: {
                            id: id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('primary', "Info", response.data.message);
                        self.loadData();
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async hapusData(id) {
                const self = this;
                if (confirm('Apakah anda yakin akan menghapus data ini') == true) {
                    await axios({
                            method: 'POST',
                            url: '/api/setup/jenis_pembayaran/delete',
                            data: {
                                id: id
                            },
                            headers: {
                                'Authorization': self.isAuthenticated
                            }
                        })
                        .then(function (response) {
                            self.notification('primary', "Info", response.data.message);
                            self.loadData();

                        }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                        });
                }
            },


            async simpan(input) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/setup/jenis_pembayaran/insert',
                        data: input,
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('primary', "Info", response.data.message);
                        self.loadData();
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async update(input) {
                    const self = this;
                    await axios({
                        method: 'POST',
                        url: '/api/setup/jenis_pembayaran/update',
                        data:  input,
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.notification('primary', "Info", response.data.message);
                        self.loadData();
            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>
<style>
    .bg-card {
        background: #dbdbdb;
    }
</style>