<template>
    <div>
        <b-modal id="modal-1" title="Tambah Jenis Pembayaran" ok-only ok-title="Accept" size="lg" no-close-on-backdrop
            hide-footer>

            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col>
                        <b-form-group>
                            <validation-provider #default="{ errors }" name="<Nama Jenis Pembayaran" rules="required">
                                <b-form-input v-model="input.nm_jenis_pembayaran" :state="errors.length > 0 ? false:null"
                                    placeholder="Nama Jenis Pembayaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>


                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-form-group>
                            <label for="InputHelp">
                                <h5>Keterangan</h5>
                            </label>
                            <quill-editor v-model="input.keterangan" :options="editorOption" row="3">
                                <div id="toolbar" slot="toolbar">
                                    <!-- Add a bold button -->
                                    <button class="ql-bold"> Bold </button>
                                    <button class="ql-italic"> Italic </button>
                                    <select class="ql-size">
                                        <option value="small" />
                                        <option selected />
                                        <option value="large" />
                                        <option value="huge" />
                                    </select>

                                    <!-- Add subscript and superscript buttons -->
                                    <button class="ql-list" value="ordered">ordered</button>
                                    <button class="ql-list" value="bullet">bullet</button>
                                </div>
                            </quill-editor>
                            <small class="text-muted"> - <i> Keterangan di perlukan unt memberikan deskripsi terkait
                                    jenis
                                    pembayaran</i></small>
                        </b-form-group>


                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-button variant="primary" class="mr-2" @click.prevent="validationForm">
                            <feather-icon icon="AlignJustifyIcon" size="15" /> Simpan
                        </b-button>
                        <b-button variant="warning" @click="close()">
                            <feather-icon icon="XIcon" size="15" /> Batalkan
                        </b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    // eslint-disable-next-line
    import 'quill/dist/quill.snow.css'
    // eslint-disable-next-line
    import 'quill/dist/quill.bubble.css'

    import {
        quillEditor
    } from 'vue-quill-editor'

    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import {
        required
    } from '@validations'

    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            quillEditor,
            BFormInput,
            BRow,
            BFormGroup,
            ValidationProvider,
            ValidationObserver
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
       
        data() {
            return {
                required,
                input : {},
                editorOption: {
                    modules: {
                        toolbar: '#toolbar',
                    },
                },
                content: ''
            }
        },
        emits:['simpan'],
        methods: {
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit('simpan', this.input); 
                        this.$root.$emit('bv::hide::modal', 'modal-1'),                      
                        this.input = {}
                    }
                })
            },
            close(){
                this.$root.$emit('bv::hide::modal', 'modal-1')
            }
        }
    }
</script>